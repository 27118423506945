<template>
    <div class="">
        <div class="d-flex justify-flex-start product-container">
            <div class="product-image-segment" align="left">
                <img class="product-image" :src="product_data.product_image ? product_data.product_image : 'https://d3vsnl8idgwrlw.cloudfront.net/product_fallback_image_large.svg'" alt="product-image">
            </div>

            <div class="pl-4 d-flex justify-space-between product-info-segment">
                <div class="d-flex flex-column">
                    <div class="py-2 d-flex flex-column justify-flex-start">
                        <!-- <p class="body-tag font-weight-regular light-grey-color-text ma-0" align="left">{{ product_data.category_name.toUpperCase() }}</p> -->
                        <p class="ma-0 body-text font-weight-medium" align="left">{{ product_data.product_name }}</p>
                    </div>
                    
                    <div class="py-2">
                        <p class="ma-0 body-text font-weight-semi-bold light-blue-color-text" align="left">{{ product_data.price_unit }} KS <span class="font-weight-regular dark-grey-color-text">{{ $t("Customer.cartProduct.each")}}</span></p>
                    </div>
                </div>

                <div class="d-flex flex-column price-quantity-segment">
                    <p class="ma-0 pb-2 body-heading font-weight-semi-bold light-blue-color-text" align="right">{{ product_data.cart_qty * product_data.price_unit }} KS</p>
                    <div class="productQtyDropDown"   v-if="quantity_editable == 'true'">
                        <!-- <div v-if="quantity_editable == 'true'" class="mt-2 pa-2 d-flex align-center rounded product-quantity-segment"> -->
                        <div v-if="addToCartLoaderFlag">
                                    <v-skeleton-loader elevation="0" type="list-item-avatar" class="py-2"></v-skeleton-loader>
                        </div>
                        <v-select v-else
                         
                         v-model="productQty"
                         :items="items"
                         menu-props="auto"
                         dense
                         hide-details
	                     single-line
                         suffix="QTY"
                         outlined
                         @change="updateQuantity"
                        >
                       </v-select>
                    </div>
                    <div v-else>
                    <p class="quatity_align" align="right">Qty {{ product_data.cart_qty }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="quantity_editable == 'true'" class="pt-2" align="left">
            <button class="body-text font-weight-medium light-grey-color-text remove-product-button" @click="removeFromCart()" :disabled="loading">
                {{ $t("Customer.cartProduct.Remove")}}</button>
        </div>
    </div>
</template>

<script>
import { axios_auth_instance_epharmacy_customer } from '../../utils/axios_utils';

export default {
    name: 'CartProductComponent',
    props: {
        product_data: {
            type: Object,
            required: true
        },
        quantity_editable: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            productQty: 1,
            items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            addToCartLoaderFlag: false,
            loading:false,
        };
    },
    mounted() {
        this.productQty = this.product_data.cart_qty;
        if (this.product_data.stock_qty > 10) {
            this.items = this.getNumberRange(1, 10)
        } else {
            this.items = this.getNumberRange(1, this.product_data.stock_qty)
        }
    },
    methods: {
        getNumberRange(start, end) {
            return Array.from({ length: end - start + 1 }, (_, index) => start + index);
        },

        updateQuantity() {
                if (this.productQty > 0 && this.productQty<= this.product_data.stock_qty) { 
                    this.addToCartLoaderFlag = true;
                    this.addToCart(this.productQty);
                }
        },

        addToCart(productQty) {
            // Call AddToCart API.
            this.$emit('updatingCart');
            let addToCartRequest = {
                productId: this.product_data.product_id,
                productQty: productQty,
            }
            axios_auth_instance_epharmacy_customer.post('/cart', addToCartRequest).then((addToCartResponse) => {
                this.product_data.cart_qty = productQty;
                this.$emit('totalPriceUpdate');
                this.addToCartLoaderFlag = false;
            }).catch((addToCartError) => {
                console.log('Error adding to cart: ', addToCartError);
                window.alert('Error updating the cart');
                this.$router.go();
            });
        },
        removeFromCart() {
            this.loading=true;
            this.$emit('updatingCart');
            this.addToCartLoaderFlag = true;
            axios_auth_instance_epharmacy_customer.delete('/cart/'+ this.product_data.product_id).then((removeFromCartResponse) => {
                this.$emit('handleRemoveProduct', this.product_data.product_id);
                this.addToCartLoaderFlag = false;
                this.loading=false;
            }).catch((removeFromCartError) => {
                console.log('Error adding to cart: ', removeFromCartError);
                window.alert('Error updating the cart');
                this.$router.go();
                this.addToCartLoaderFlag = false;
                this.loading=false;
            });
        }
    }
}
</script>

<style lang="scss">
@import '../../scss/global.scss';
@import '../../scss/classes.scss';


select option::before {
  content: "QTY ";
}
.product-container {
    width: 100%;
}

.product-image {
    height: 80px;
    object-fit: fill;
    aspect-ratio: 1;
}

.product-info-segment {
    width: 100%;
}

.product-quantity-segment {
    background-color: #F4F6FA;
    // width: 40px;
}

.price-quantity-segment {
    justify-content: flex-end;
}

.product-quantity-width {
    width: 30px;
}

.remove-product-button {
    text-decoration: underline;
}

.quantity-adjust-icon {
    width: 20px;
    height: 20px;
}
.productQtyDropDown {
        width: 139px;
    }
.quatity_align{
    color: var(--grays-grey-3, #828282);
    font-family: 'Inter' !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
@media only screen and (max-width: 400px) {
    .productQtyDropDown {
        width: 105px;
    }
}
</style>